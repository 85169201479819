import * as React from 'react'

import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'

import * as S from './NotFoundPage.styles'
import Layout from '../../Layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet title="Ops... página não encontrada!" />

    <S.PageError
      title="Ops... página não encontrada!"
      buttonUrl="/"
      linkTag={Link}
      buttonLabel="Voltar para a página inicial"
    />
  </Layout>
)

export default NotFoundPage
