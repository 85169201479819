import styled from 'styled-components'

import { PageError as PageErrorComponent } from '@interco/affiliate-shopping-ui'

import { mediaQuery } from '../../../assets/styles/variables'

export const PageError = styled(PageErrorComponent)`
  margin: 3rem auto;
  align-items: center;

  @media ${mediaQuery.minMD} {
    margin: 12rem auto;
  }
`
